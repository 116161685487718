export const setItemStorage = <T>(key: string, element: T) => {
  localStorage.setItem(key, JSON.stringify(element));
};

export function getItemStorage(key: string, parse: false): string;
export function getItemStorage<T = unknown>(key: string, parse?: true): T;
export function getItemStorage<T = unknown>(
  key: string,
  parse?: true,
  canBeNull?: true
): T | null;

export function getItemStorage<T = unknown>(
  key: string,
  parse = true,
  canBeNull = true
): string | T | null {
  const item = localStorage.getItem(key);

  if (item === null && canBeNull) {
    return null;
  }

  if (item) {
    if (parse === false) {
      return item;
    }

    return JSON.parse(item);
  }

  throw new Error('Item not exist');
}
